import React from 'react'

import { AppRegistry, View } from 'react-native'

import Navigation from './Navigation'

const App = () => {
  return (
    <Navigation />
  )
}

AppRegistry.registerComponent('Originest', () => App)
AppRegistry.runApplication('Originest', {
  rootTag: document.getElementById('root')
})
