import React, { useRef } from 'react'
import { View, Text, TouchableOpacity, Linking, StyleSheet } from 'react-native'
import Swiper from 'react-native-web-swiper'
import Header from './Header'

const mailToAddress = 'jobs@originest.com'
const mailToSubject = 'Job Application'
const mailToBody = 'See attached resume <Attach Resume>'
const mailTo = () => {
  Linking.openURL(`mailto:${mailToAddress}?subject=${mailToSubject}&body=${mailToBody}`)
}

export default ({ navigation }) => {
  const swiperRef = useRef(null)

  return (
    <View style={styles.container}>
      <Header />
      <Swiper
        ref={swiperRef}
        minDistanceForAction={0.1}
      >
        <View style={styles.slide}>
          <Text style={styles.h1}>software engineer</Text>
          <Text style={styles.h2}>design & develop software systems</Text>
          <TouchableOpacity onPress={mailTo} style={styles.link}>
            apply
          </TouchableOpacity>
        </View>
        <View style={styles.slide}>
          <Text style={styles.h1}>product designer</Text>
          <Text style={styles.h2}>design & develop product</Text>
          <TouchableOpacity onPress={mailTo} style={styles.link}>
            apply
          </TouchableOpacity>
        </View>
      </Swiper>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  slide: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  h1: {
    fontSize: 48,
    fontWeight: 100
  },
  h2: {
    fontSize: 24,
    fontWeight: 100
  },
  link: {
    marginTop: 21,
    fontSize: 24,
    color: 'blue'
  }
})
