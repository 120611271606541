import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'

export default () => {
  const navigation = useNavigation()

  return (
    <TouchableOpacity onPress={() => navigation.navigate('Home')}>
      <Text style={styles.logo}>originest</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  logo: {
    fontSize: 48,
    fontWeight: 200
  }
})
