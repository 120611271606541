import React from 'react'
import { View, Text, TouchableOpacity, Linking, Dimensions, StyleSheet } from 'react-native'
import { useNavigationState } from '@react-navigation/native'
import Logo from './Logo'

const isSmallDevice = Dimensions.get('window').width < 768

const mailToAddress = 'partner@originest.com'
const mailToSubject = 'Partner with Us'
const mailToBody = 'Partner'
const mailTo = () => {
  Linking.openURL(`mailto:${mailToAddress}?subject=${mailToSubject}&body=${mailToBody}`)
}

export default () => {
  return (
    <View style={styles.container}>
      <Logo />
      {!isSmallDevice &&
       <View style={[styles.row, styles.links]}>
         <TouchableOpacity
           onPress={() => navigation.navigate('vision')}
         >
           <Text style={styles.link}>vision</Text>
         </TouchableOpacity>
         <TouchableOpacity onPress={() => navigation.navigate('portfolio')}>
           <Text style={styles.link}>portfolio</Text>
         </TouchableOpacity>
         <TouchableOpacity onPress={() => navigation.navigate('jobs')}>
           <Text style={styles.link}>jobs</Text>
         </TouchableOpacity>
         <TouchableOpacity onPress={mailTo}>
           <Text style={styles.link}>invest</Text>
         </TouchableOpacity>
         <TouchableOpacity onPress={mailTo}>
           <Text style={styles.link}>partner</Text>
         </TouchableOpacity>
       </View>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 21,
    flexDirection: 'row'
  },
  links: {
    marginLeft: 42,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end'
  },
  link: {
    marginLeft: 7,
    marginRight: 7,
    fontSize: 24,
    fontWeight: 100,
    color: 'blue'
  }
})
