import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import React from 'react'

import Home from './Home'
import Portfolio from './Portfolio'
import Jobs from './Jobs'

const NavigationStack = createStackNavigator()

const routes = {
  config: {
    screens: {
      Home: '',
      Vision: 'vision',
      Portfolio: 'portfolio',
      Jobs: 'jobs'
    }
  }
}

export default ({ currentUser }) => {
  return (
    <NavigationContainer linking={routes}>
      <NavigationStack.Navigator screenOptions={{ headerShown: false, title: 'Originest' }}>
        <NavigationStack.Screen name="Home" component={Home} />
        <NavigationStack.Screen name="Vision" component={Home} />
        <NavigationStack.Screen name="Portfolio" component={Portfolio} />
        <NavigationStack.Screen name="Jobs" component={Jobs} />
      </NavigationStack.Navigator>
    </NavigationContainer>
  )
}
